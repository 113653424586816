import React from 'react'
import Layout from '../../../components/Layout'
import Nav from '../../../components/Nav'

// Import images
import previousModal from '../../../images/shopify-previous-fx-modal.png'
import rateUpfront from '../../../images/shopify-rate-selection-upfront.png'
import deactivatedMarket from '../../../images/shopify-deactivated-market.png'
import exploration001 from '../../../images/shopify-modal-exploration-001.png'
import exploration002 from '../../../images/shopify-modal-exploration-002.png'
import exploration003 from '../../../images/shopify-modal-exploration-003.png'
import exploration004 from '../../../images/shopify-modal-exploration-004.png'
import exploration005 from '../../../images/shopify-modal-exploration-005.png'
import solution from '../../../images/shopify-modal-solution.png'

export default function fxModal() {
    return (
        <Layout>
            <Nav></Nav>
            <div className="case-study">
                <main>
                    <div className="wrapper">
                        <div className="case-study--hero">
                            <span className="lozenge">Case study</span>
                            <h1>Improving foreign exchange one modal at a time</h1>
                            <h2>How might we make foreign currency exchange less confusing and empower merchants with smart defaults?</h2>
                        </div>
                        <section className="case-study--problem">
                            <h3>Background</h3>
                            <p>Foreign exchange represents 60% of the revenue that is generated by the Merchant Services organization of Shopify. For a merchant who is trying to sell internationally, it's in their best interest to utilize Shopify Payments as a payment gateway to handle currency exchange from their customers on their behalf.</p>
                            <p>Merchants who de-activate Shopify Payments often cite issues with foreign currency or multi-currency as a reason. The churn rate for this cohort of merchants is roughly 10%.</p>
                            <p>Merchants who add new markets, where applicable, the local currency of that country or countries is converted by default.</p>
                        </section>
                        <section classNAme="case-study--problem">
                            <h3>Problem Statement</h3>
                            <p>Merchants who are internationally minded turn off Shopify Payments. In their reasons for deactivating, merchants cite how confusing foreign exchange rates and currency conversion fees are calculated and its affect on their bottom lines.</p>
                            <p>What we know is that merchants who are more likely to churn are those that have switched foreign exchange rates to manual and then back to automatic rates.</p>
                            <p>When switching back and forth, merchants are having to pay more currency conversion fees in total for orders that were using the manual rate. They did not realize this.</p>
                        </section>
                        <section className="case-study--problem">
                            <h3>Framing the solution</h3>
                            <p>In order for merchant's bottom line to not be affected and therefore cause confusion, clarity of impact needs to be more apparent within this modal.</p>
                        </section>
                        <section>
                            <h3>Previous modal</h3>
                            <p>This modal can be found in Shopify Markets within a specific market inside of the Products &amp; Pricing page.</p>
                            <img src={previousModal} alt="" width="100%" />
                        </section>
                        <section>
                            <h3>Apply systems thinking</h3>
                            <p>Is it the modal that's broken or is it the whole system that the modal belongs to? If this the case, then where in the market creation flow do we to change?</p>
                            <ol>
                                <li>Perhaps we should alert merchants that the currency was smartly defaulted to the local currency of the market?</li>
                                <li>Or do we take a more drastic approach and prevent market activation until a rate is chosen? Much like ensuring shipping rates are added to the country.</li>
                            </ol>
                            <img src={rateUpfront} alt="Rate up front variation" />
                            <img src={deactivatedMarket} alt="Rate up front variation" />
                            <h3>Conclusions</h3>
                            <p>It's not in the best interest of a merchant to stop their flow of a market especially if shipping is already in place and we know that the smart default of local currency makes sense for contextualized buying experiences. Flagging or indicating that currency changes have occurred where we might introduce more confusion is another scenario that should also be avoided. </p>
                            <p>To that end, I correctly assessed that the modal was indeed the place to put most effort forth.</p>
                        </section>
                        <section>
                            <h3>Next steps, redesign the modal</h3>
                            <p>The modal needed to accomplish the following:</p>
                            <ol style={{width:'40vw'}}>
                                <li>Show with confidence that the smart defaults we apply on markets to change the base currency to the local currency of the market is indeed the better choice.</li>
                                <li>Indicate clearly that choosing manual rates incurs more fees for merchants.</li>
                            </ol>
                        </section>
                        <section>
                            <h3>Explorations</h3>
                            <img src={exploration001} alt="Exploration 1" />
                            <img src={exploration002} alt="Exploration 1" />
                            <img src={exploration003} alt="Exploration 1" />
                            <img src={exploration004} alt="Exploration 1" />
                            <img src={exploration005} alt="Exploration 1" />
                        </section>
                        <section>
                            <h3>Final solution</h3>
                            <p>After exploring ideas that brought forth the modal to the page, it made more sense to keep this information within the modal as activated by the link above base currency. Match the expectation of the merchant but with more clarity within the modal.</p>
                            <img src={solution} alt="The solution" />
                            <p>By using a radio group component, the selection within the radio group changes the bottom section to reflect the most pertinent information based on selection choice. </p>
                        </section>
                        <section>
                            <h3>Impact metrics</h3>
                            <p>Due to being laid off from Shopify prior to this being deployed to production, impact has yet to be measured.</p>
                        </section>
                    </div>
                    
                </main>
            </div>
        </Layout>   
    )
}